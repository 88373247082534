<template>
  <footer class="te-footer bg-co-gray-700">
    <div class="co-container-lg px-4 py-5">
      <slot name="top-menu" />

      <div class="flex flex-col text-[0.75rem] md:flex-row">
        <ul class="te-footer-bottom mb-2 mr-auto flex flex-wrap md:mr-auto">
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/company'"
              class="block p-0 text-co-gray-500 hover:text-co-gray-500"
            >
              運営会社
            </a>
          </li>
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/terms'"
              class="block p-0 text-co-gray-500 hover:text-co-gray-500"
            >
              利用規約
            </a>
          </li>
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/privacypolicy'"
              class="block p-0 text-co-gray-500 hover:text-co-gray-500"
            >
              プライバシーポリシー
            </a>
          </li>
          <li class="mb-2">
            <a
              :href="$host() + '/law'"
              class="block p-0 text-co-gray-500 hover:text-co-gray-500"
            >
              特定商取引法に関する表示
            </a>
          </li>
        </ul>
        <ul class="te-footer-sns flex flex-wrap">
          <li class="mr-6 md:mr-5">
            <a
              class="block px-0 py-2 text-[1.25rem] text-co-gray-500 hover:text-co-gray-300 md:py-0"
              href="https://www.facebook.com/techacademy"
              target="_blank"
            >
              <i
                class="fa fa-facebook"
                aria-hidden="true"
                aria-label="Facebook"
                title="Facebook"
                role="link"
              />
            </a>
          </li>
          <li>
            <a
              class="block px-0 py-2 text-[1.25rem] text-co-gray-500 hover:text-co-gray-300 md:py-0"
              href="https://twitter.com/techacademy"
              target="_blank"
            >
              <FontAwesomeIcon :icon="faXTwitter" />
            </a>
          </li>
        </ul>
      </div>

      <div class="text-center text-co-gray-500">
        <small class="text-[0.875em]">&copy;2009 - {{ currentYear }} KiRAMEX
          <span class="hidden sm:inline-block"> CORPORATION.</span>
        </small>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useDateFormat, useNow } from '@vueuse/core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXTwitter } from '@awesome.me/kit-65d95819ce/icons/classic/brands'

const currentYear = useDateFormat(useNow(), 'YYYY')
</script>
